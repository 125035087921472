/* @import url('https://fonts.googleapis.com/css?family=Josefin+Sans'); */

html {
  font-size: 16px;
}

body {
  font-size: var(--font-size-base);
  color: var(--text-color);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--headings-color);
}

.App {
  text-align: center;
  color: var(--text-color);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: var(--brand-primary);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rsc-os-options {
  display: flex;
  padding-left: var(--avatar-size);
  flex-wrap: wrap;
}

.rsc-os-option-element {
  font-size: 13px;
  border: 2px solid var(--brand-secondary);
  background-color: transparent;
  color: var(--brand-secondary);
  cursor: pointer;
}

.react-chatbot-kit-chat-container {
  position: fixed;
  display: flex;
  top: 1rem;
  bottom: 1rem;
  left: 1rem;
  right: 1rem;
  overflow: hidden;
  width: calc(100% - 2rem);
  height: var(--chatbot-height);
  border-radius: 1rem;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
  flex-direction: column;
}

.react-chatbot-kit-chat-inner-container {
  display: flex;
  height: unset;
  flex: 1;
  flex-direction: column;
  height: 100%;
}

.react-chatbot-kit-chat-message-container {
  flex: 1;
  height: unset;
  padding: 0 1rem;
}

.react-chatbot-kit-chat-header {
  padding: 1rem;
  background-color: var(--headings-color);
  font-size: 0.9rem;
  color: var(--text-color-light);
  font-family: var(--font-family-base);
}

.react-chatbot-kit-chat-bot-message-container {
  padding-left: var(--avatar-size);
}

.react-chatbot-kit-chat-bot-avatar {
  margin-left: calc(var(--avatar-size) * -1);
}

.react-chatbot-kit-chat-bot-avatar-container,
.react-chatbot-kit-user-avatar-container {
  width: var(--avatar-size);
  height: var(--avatar-size);
}

.react-chatbot-kit-chat-bot-avatar-container {
  margin-right: 0;
  background-color: #8adcff;
}

.react-chatbot-kit-chat-bot-avatar-container img {
  width: 100%;
  height: auto;
}

.react-chatbot-kit-chat-bot-avatar-letter {
  color: white;
}

.react-chatbot-kit-chat-input-container {
  position: relative;
}

.react-chatbot-kit-chat-input {
  padding: 1rem;
  border-bottom-left-radius: 1rem;
  font-size: 0.9rem;
}

.react-chatbot-kit-chat-bot-message,
.react-chatbot-kit-user-chat-message {
  padding: 1rem;
  border-radius: 1rem;
  color: var(--text-color);
  line-height: 1.3;
  box-shadow: 0 0 0.1rem 0 rgba(0, 0, 0, 0.05);
}

.react-chatbot-kit-chat-bot-message {
  max-width: 65%;
  margin-left: 1rem;
  flex: 1;
  background-color: var(--brand-primary) !important;
  color: white;
}

.react-chatbot-kit-user-chat-message {
  background-color: var(--gray-light) !important;
}

.react-chatbot-kit-chat-bot-message-arrow,
.react-chatbot-kit-user-chat-message-arrow {
  top: 16px;
}

.react-chatbot-kit-chat-bot-message-arrow {
  border-right-color: var(--brand-primary) !important;
}

.react-chatbot-kit-user-chat-message-arrow {
  border-right-color: var(--gray-light) !important;
}

/* #chatbot-loader #chatbot-loader-dot1,
#chatbot-loader #chatbot-loader-dot2,
#chatbot-loader #chatbot-loader-dot3 {
  background-color: var(--brand-primary) !important;
} */
