.options-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.option-button {
  padding: 0.6rem 1rem;
  border-radius: 1.4rem;
  border: 2px solid var(--brand-secondary);
  color: var(--brand-secondary);
  background: white;
  text-align: center;
  cursor: pointer;
  box-shadow: 0 0 0.1rem 0 rgba(0, 0, 0, 0.05);
}
