:root {
  --font-family-base: "Helvetica Neue";
  --font-size-base: 1rem;
  --brand-primary: #00becc;
  --brand-secondary: #ff6694;
  --brand-tertiary: #be80ff;
  --brand-dark: #190958;
  --text-color: #1d2125;
  --text-color-light: #ffffff;
  --headings-color: #190958;
  --gray-light: #f6f6f6;
  --avatar-size: 3.2rem;
  --chatbot-height: calc(100% - 2rem);
}
