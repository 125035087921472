.tool-wrap {
  max-width: 20rem;
  width: calc(100% - 2rem);
  margin-left: auto;
  margin-right: auto;
  padding: 0.5rem;
  background-color: var(--brand-dark);
  border-radius: 4px;
}

.tool-container {
  position: relative;
  padding-top: 120%;
  border: 1px solid red;
}

.tool-container::before {
  position: absolute;
  top: 0;
  left: 50%;
  width: 2px;
  height: 100%;
  margin-left: -1px;
  background-color: rgba(255,255,255, 0.5);
}
